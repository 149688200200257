function gaTrack(type, cat, act, lab) {
    if(typeof gtag === 'function'){
        gtag('event', type, {
            'event_category': cat,
            'event_action': act,
            'event_label': lab
        });
    }
}
function getParameterByName(name, url) {
    if (!url){ url = window.location.href; }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results){return null;}
    if (!results[2]){return '';}
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function getDataCatValue(val) {
    var v = jQuery('option:selected', val);
    if(v.length){
        var attr = v.attr('data-name');
        if (typeof attr !== typeof undefined && attr !== false) {
            return attr;
        }
    }
    return '';
}
function getSearchType(t) {
    if(!t || t===undefined){
        return 'circolari e bandi';
    }
    return t;
}
function getMonthName(m){
    var mesi = ['gennaio','febbraio','marzo','aprile','maggio','giugno','luglio','agosto','settembre','ottobre','novembre','dicembre'];
    if(m>0){
        return mesi[m-1];
    }
    return '';
}
function addSrcValue(curr, next){
    if(curr===''){
        return next;
    }
    if(next!==''){
        return curr + ' - ' + next;
    }
    return curr;
}

function checkRadioValue(val) {
    var cat = jQuery('#ar_search_cat');
    cat.attr('disabled',true).addClass('disabled');
    jQuery('.label-servizi',jQuery('#ar_search')).addClass('disabled');
    if(val === 'servizi'){
        cat.attr('disabled',false).removeClass('disabled');
        jQuery('.label-servizi',jQuery('#ar_search')).removeClass('disabled');
    }
}

function initUIP() {

    // DETECT MOBILE
    function isTouchDevice() {  return 'ontouchstart' in window; /* || 'onmsgesturechange' in window; */}
    var isDesktop = /*(window.screenX === 0) && */!isTouchDevice();
    if (isDesktop) {
        jQuery( "body" ).removeClass( "mobile" );
    }

    // lente cerca
    jQuery( "#lente" ).click(function() {
        jQuery( ".cercadietro" ).toggleClass( "apriti" );
        jQuery( "header" ).toggleClass( "scendi" );
        jQuery( ".menu_espanso" ).toggleClass( "scendigiu" );
    });

    // owl carousel

    jQuery('#owl-home').owlCarousel({
        loop:true,
        autoplay: true,
        margin:0,
        nav:false,
        items: 1,
        dots: true,
    });

    var owlNews = jQuery('#owl-news').owlCarousel({
        loop:true,
        autoplay: false,
        margin:0,
        nav:false,
        items: 1,
        dots: true,
    });
    owlNews.trigger('stop.owl.autoplay');
    var tk = setTimeout(function () {
        owlNews.trigger('play.owl.autoplay');
    },2500);

    jQuery('.owl-carousel.slider-aziende').owlCarousel({
        loop:true,
        autoplay: true,
        margin:0,
        nav:false,
        items: 1,
        dots: true,
    });

    jQuery('.owl-carousel.slider-dettaglio-azienda').owlCarousel({
        loop:true,
        autoplay: true,
        margin:0,
        nav:true,
        items: 1,
        dots: true,
    });


    jQuery('#owl-home .owl-dots').wrap('<div class="container fordots"></div>');
    jQuery('#owl-home .fordots').wrap('<div class="centering_dots"></div>');


    jQuery( ".menu_hamburger" ).click(function() {
        jQuery( ".menu_open" ).toggleClass( "aprisx" );
        jQuery( "body" ).toggleClass( "fixati" );
        jQuery( ".cercadietro" ).toggleClass( "fixati_sx" );
        jQuery( ".cercadietro" ).removeClass( "apriti" );
        jQuery( "header" ).removeClass( "scendi" );
        jQuery( ".menu_espanso" ).removeClass( "scendigiu" );
    });

    var hamburger = jQuery('.menu_hamburger');
    hamburger.click(function() {
        jQuery("#hamburger-icon").toggleClass('active');
        return false;
    });


    if(jQuery('#ar_search').length){
        jQuery('input[type=radio][name=ar_search_type]').change(function() {
            checkRadioValue(jQuery(this).val());
        });
        checkRadioValue(jQuery('input[name=ar_search_type]:checked', '#ar_search').val());

        jQuery('.reset',jQuery('#ar_search')).click(function (event) {
            event.preventDefault();
            jQuery('#ar_search_cat').attr('disabled',true).addClass('disabled').val(0);
            jQuery('.label-servizi',jQuery('#ar_search')).addClass('disabled');
            jQuery('input[name=ar_search_type]:checked', '#ar_search').attr("checked", false);
            jQuery('#ar_search_key').val('');
            jQuery('#ar_search_month').val(0);
        });

        jQuery( "#ar_search" ).submit(function( event ) {
            var str = '';
            str = addSrcValue(str, jQuery('#ar_search_key').val());
            str = addSrcValue(str, getSearchType(jQuery('input[name=ar_search_type]:checked', '#ar_search').val()));
            str = addSrcValue(str, getDataCatValue(jQuery('#ar_search_cat')));
            str = addSrcValue(str, getMonthName(jQuery('#ar_search_month').val()));
            gaTrack('event', 'search','click',str);
        });

    }
    
    if(jQuery('.trackdownload').length){
        jQuery('.trackdownload').click(function (event) {
            if(jQuery(this).attr('data-track')){
                gaTrack('event', 'download','click',jQuery(this).attr('data-track'));
            }
        });
    }
    if(jQuery('.trackphone').length){
        jQuery('.trackphone').click(function (event) {
            gaTrack('event','call','click','telefonata ' + jQuery(this).attr('href').replace('tel:',''));
        });
    }

    if(getParameterByName('login')==='ok'){
        var tmpLab = 'login ' + jQuery('#username_ar').text();
        if(getParameterByName('f')==='footer'){
            tmpLab = 'login footer ' + jQuery('#username_ar').text();
        }
        var t = setTimeout(function () {
            gaTrack('event','button','click',tmpLab);
        },500);
    }

    jQuery(".sharePopup").jsSocials({
        shareIn: "popup",
        showLabel: false,
        shares: [{
            share: "facebook",
            logo: _root + "/dist/images/fb.png"
        }, {
            share: "twitter",
            logo: _root + "/dist/images/tw.png"
        }]
    });


}

function initMap() {
    if(jQuery('#mappa').length){
        var myLatLng = {lat: 43.716122, lng: 10.392514};

        var map = new google.maps.Map(document.getElementById('mappa'), {
            zoom: 17,
            center: myLatLng
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: 'Unione Industriale Pisana',
            icon : {
                url: '/wp-content/themes/unioneindustrialepisana/dist/images/pin_map.png',
                size: new google.maps.Size(100, 100),
                anchor: new google.maps.Point(25, 50),
                scaledSize: new google.maps.Size(50, 50)
            }
        });

        var contentString = '<div id="content">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<h3 id="firstHeading" class="firstHeading">Unione Industriale Pisana</h3>'+
            '<div id="bodyContent">'+
            '<p>Via Volturno 41/43 - 56126 Pisa<br>Tel: 050/913511<br>Fax: 050/49690</p>'+
            '</div>'+
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });

    }

}

function loadSelectAziende(page) {

    jQuery('.loading-layer').addClass('visible');
    jQuery('.loading-spinner').addClass('visible');

    var settoreMerceologicoSelected = jQuery('select#settore-merceologico').val();
    var tipologiaSelected = jQuery('select#tipologia').val();
    var inputText = jQuery('input#input-search').val();
    var postsPerPage = jQuery('section.uip-aziende-list').data('postperpage');

    jQuery.ajax({
        type: 'POST',
        url: ajaxurl,
        data: {
            action: 'load_aziende',
            settore: settoreMerceologicoSelected,
            tipologia: tipologiaSelected,
            input_text: inputText,
            page: page,
            postsperpage: postsPerPage
        },
        dataType: "json",
        success: function (data) {

            //console.log(data);

            jQuery('.aziende-list-preview').html('');

            var htmlContent = '';

            for (var i = 0; i < data.posts.length; i++) {

                htmlContent += '<div class="col-xs-6 col-md-2"><div class="single-item">';
                htmlContent += '<a href="' + data.posts[i].linkDetail + '">';
                htmlContent += '<img class="border-img" src="' + data.posts[i].imgPreview + '" alt="logo">';
                htmlContent += '<div class="azienda-name">' + data.posts[i].title + '</div>';
                htmlContent += '</a></div></div>';
            }

            jQuery('.aziende-list-preview').html(htmlContent);

            jQuery('.pagination_nav ul').html('');

            if(data.pagination.maxpage > 1) {

                var pagi = '';

                if (data.pagination.before !== '') {
                    pagi += '<li class="firstpage" data-page="1" ><a class="target-page" href="#"> &laquo;</a></li>' +
                        '<li><a class="no-click" href="#">' + data.pagination.before + '</a></li>';
                }
                var activepage = '';
                for (var indi = data.pagination.init; indi <= data.pagination.end; indi++) {
                    activepage = '';
                    if (indi === page) {
                        activepage = 'current';
                    }

                    pagi += '<li class="numerico" data-page="' + indi + '"><a class="target-page ' + activepage + '" href="#">' + indi + '</a></li >';
                }
                if (data.pagination.after !== '') {
                    pagi += '<li><a class="no-click" href="#">' + data.pagination.after + '</a></li>' +
                        '<li class="lastpage" data-page="' + data.pagination.maxpage + '"><a class="target-page"> &raquo;</a></li>';

                }

                jQuery('.pagination_nav ul').html(pagi);

            }

            jQuery('.loading-layer').removeClass('visible');
            jQuery('.loading-spinner').removeClass('visible');

        }

    });
}

jQuery('#aziende-search select').on('change', function() {

    loadSelectAziende(1);

});


function delay(callback, ms) {
    var timer = 0;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

jQuery('#aziende-search #input-search').on('keyup', delay(function () {

    loadSelectAziende(1);

}, 700));

jQuery(document).on('click', '.azienda-pagination .pagination_nav ul li a.target-page', function(e) {

    e.preventDefault();
    var page = jQuery(this).parent().data('page');

    loadSelectAziende(page);

});

if ((window.location.href.indexOf("#bandi") > -1) || (window.location.href.indexOf("key_pnrr") > -1)) {
    jQuery('html, body').animate({
        scrollTop: jQuery("#bandipnrr").offset().top - 170
    }, 1000);
} 

    jQuery('.primary-mobile').click(function(){
        jQuery(this).children('.submenu-mobile').toggleClass('active');
        jQuery(this).toggleClass('active');
    });